export const GA_TRACKING_ID = "G-Q2ECWVXXD6";
export const AW_TRACKING_ID = "AW-10917344955";
export const AW_CODE = "FqCMCNLGnsMDELvt5dUo";
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
    window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
    });
};

type GTagEvent = {
    action: string;
    category: string;
    label: string;
    value: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};

function createFunctionWithTimeout(callback: any, optTimeout: number) {
    let called = false;
    function fn() {
        if (!called) {
            called = true;
            callback();
        }
    }
    setTimeout(fn, optTimeout || 1000);
    return fn;
}
/**
 * gtag 跟踪事件
 *
 * @param {string} name 事件名称
 * @param {Record<string, unknown>} params 参数
 * @returns {Promise<any>}
 */
function trackEvent(
    name: string,
    params: Record<string, unknown> = {}
): Promise<any> {
    if (!(window as any).gtag) return Promise.reject(new Error("no gtag"));
    if (process.env.NEXT_PUBLIC_MODE !== "production")
        return Promise.reject(new Error("no production"));
    return new Promise((resolve, reject) => {
        try {
            (window as any).gtag("event", name, {
                ...params,
                event_callback: createFunctionWithTimeout(() => {
                    resolve(true);
                }, 800),
            });
        } catch (error) {
            reject(error);
        }
    });
}

/**
 * @description Google Ads 转化事件
 * @param id 事件id
 * @returns
 */
function trackEventConversion(id: string) {
    return trackEvent("conversion", { send_to: id });
}

//  用户注册GoogleAd转换跟踪
function trackSignUpConversion() {
    return trackEventConversion("AW-10917344955/FqCMCNLGnsMDELvt5dUo");
}
// 跟踪signup
function trackSignUpEvent(params: Record<string, unknown>) {
    return trackEvent("sign_up", params);
}
// 跟踪signup并加上GoogleAd转换跟踪
function trackSignUp(
    params: Record<string, unknown>,
    isRegister: boolean = true
) {
    if (!isRegister) return Promise.reject(new Error("not new register"));
    // if (!getStorageInfo()) return Promise.reject(new Error("no login"));
    return Promise.all([trackSignUpEvent(params), trackSignUpConversion()]);
}

// //  用户提交contact页面的表单
// function trackContact() {
//   return trackEventConversion('AW-10963387731/0l4zCPDNyNIDENOK4Oso')
// }

// //  用户点击首页任意一个get started按钮或use for free
// function trackStart() {
//   return trackEventConversion('AW-10963387731/SCfkCPPNyNIDENOK4Oso')
// }

export const trackEventFn = {
    signUp: trackSignUp,
    signUpEvent: trackSignUpEvent,
    signUpConversion: trackSignUpConversion,
};
