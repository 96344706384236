import fetch from "isomorphic-unfetch";
// import data from "@images/report/data.png";
import { camel2Under, under2Camal } from "./lineToHump";
import { hyphenate } from "./format";
import { getBureidHeadersFromServerContext } from "../utils/headers";
import { getCookie } from "cookies-next";

function getToken(): any {
    const access: any = getCookie("authorized-token");
    if (!access) return undefined;
    return JSON.parse(access).accessToken;
}

class SelfFetch {
    // 让每个promise请求都trycatch
    async baseMethod(fn: Promise<Response>) {
        if (fn) {
            try {
                const res = await fn;

                const data = await res.json();
                // console.log("pppp9999", data);
                // return data;

                return under2Camal(data);
            } catch (error) {
                return null;
            }
        }
    }

    async get(url: string, context: any) {
        // console.log("00001", process.browser, url);
        const headers = getBureidHeadersFromServerContext(
            context
        ) as HeadersInit;
        // console.log("0000", headers, url);
        return await this.baseMethod(
            fetch(url, {
                headers: {
                    ...headers,
                    "system-id": "8",
                    // authorization: getToken(),
                    "Content-Type": "application/json",
                },
            })
        );
    }

    async get2(url: any, context: any, headers?: any) {
        // console.log(params);

        return await this.baseMethod(
            fetch(url, {
                headers: headers,
            })
        );
    }

    async post(url: any, context: any, options?: any) {
        // console.log(options);
        const headers = getBureidHeadersFromServerContext(
            context
        ) as HeadersInit;
        const res = await this.baseMethod(
            fetch(url, {
                headers: {
                    ...headers,
                    ...options?.headers,
                    // authorization: getToken(),
                    "Content-Type": "application/json",
                    "system-id": "8",
                },
                method: "POST",
                body: JSON.stringify(camel2Under(options)),
            })
        );
        return res;
    }

    async put(url: any, context: any, options?: any) {
        const headers = getBureidHeadersFromServerContext(
            context
        ) as HeadersInit;
        const res = await this.baseMethod(
            fetch(url, {
                headers: {
                    ...headers,
                    ...options?.headers,
                    // authorization: getToken(),
                    "Content-Type": "application/json",
                    "system-id": "8",
                },
                method: "Put",
                body: JSON.stringify(options),
            })
        );
        return res;
    }
}

export default SelfFetch;
