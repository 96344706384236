import * as React from "react";
import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useWidth } from "@/utils/resize";
import { useTranslation } from "next-i18next";
// import { getCookie, removeCookies } from "cookies-next";
import {
    handleToMobileUser,
    handleToMoblieList,
    handleToMoblieLogin,
} from "@/utils/sys";
import { Modal } from "antd";
import { handleToUrl } from "../../utils/sys";
import { getToken, isExprise, removeToken } from "@/utils/auth";
const FooterBtns = () => {
    const { width, display } = useWidth();
    const [isAuthorized, setAuthorized] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const { t, i18n } = useTranslation("nav");
    const isPc = width > 1023;
    const handleClick = (index: number) => {
        if (isExprise() && index === 2) {
            removeToken();
            setModalOpen(true);
            return;
        }

        index === 1
            ? handleToUrl("/mobile/influencers/sales")
            : isAuthorized
            ? handleToMobileUser()
            : setModalOpen(true);
    };
    useEffect(() => {
        const isAuth = getToken() ? true : false;
        if (isAuth) {
            setAuthorized(isAuth);
        }
    }, []);

    return !isPc ? (
        <div className={styles.footerBtns}>
            <a
                className={[styles.btnItem, styles.active].join(" ")}
                onClick={() => {
                    if (window.location.pathname !== "/") {
                        window.location.href = "/";
                    }
                }}
            >
                <i className="iconfont icon-a-23shouye1"></i>
                <span>{t("home")}</span>
            </a>
            <a className={styles.btnItem} onClick={() => handleClick(1)}>
                <i className="iconfont icon-bangdan"></i>
                <span>{t("list")}</span>
            </a>
            <a className={styles.btnItem} onClick={() => handleClick(2)}>
                <i className="iconfont icon-gerenzhongxin1"></i>
                <span>{t("me")}</span>
            </a>
            <Modal
                centered
                visible={modalOpen}
                closable={false}
                keyboard={false}
                footer={null}
                bodyStyle={{ padding: "0" }}
                wrapClassName={"footerBtns"}
            >
                <div className={`${styles["modal-content"]}`}>
                    <div className="py-[27px] px-[25px] text-center text-[14px]">
                        Dear, Please register or login to unlock more features!
                    </div>
                    <div className={`flex h-[50px] ${styles.modalFooter}`}>
                        <div
                            className={`w-[50%] flex items-center justify-center text-[18px] text-center cursor-pointer`}
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            Cancel
                        </div>
                        <div
                            className="w-[50%] flex items-center justify-center  text-[18px] text-center cursor-pointer"
                            onClick={() => {
                                handleToMoblieLogin();
                                // console.log(123123);
                            }}
                        >
                            Confirm
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    ) : null;
};

export default FooterBtns;
