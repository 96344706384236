export const handleLogin = () => {
  window.location.href = process.env.NEXT_PUBLIC_LOGIN_URL! + "?fromPath=" + window.location.href
}

export const handleToPortal = () => {
  window.location.href = process.env.NEXT_PUBLIC_PORTAL_URL! + "/home"
}
export const handleToPotential = () => {
  window.location.href = process.env.NEXT_PUBLIC_PORTAL_URL! + "/influencer/potential"
}
export const handleToVideo = () => {
  window.location.href = process.env.NEXT_PUBLIC_PORTAL_URL! + "/videos/list?name=soaring"
}

export const handleToMusic = () => {
  window.location.href = process.env.NEXT_PUBLIC_PORTAL_URL! + "/content/songs?name=popular"
}

export const handleToTag = () => {
  window.location.href = process.env.NEXT_PUBLIC_PORTAL_URL! + "/content/hashtags?name=popular"
}

export const handleToCollection = () => {
  window.location.href = process.env.NEXT_PUBLIC_PORTAL_URL! + "/tool/favorites"
}

export const handleToMonitor = () => {
  window.location.href = process.env.NEXT_PUBLIC_PORTAL_URL! + "/toolkit/monitor"
}

export const handleToGlobalGoodsRank = () => {
    window.location.href =
        process.env.NEXT_PUBLIC_PORTAL_URL! + "/products/sales";
};

export const handleToGlobalGoodsVideo = () => {
    window.location.href =
        process.env.NEXT_PUBLIC_PORTAL_URL! + "/videos/products";
};

export const handleToGlobalProductVideo = () => {
  window.location.href = process.env.NEXT_PUBLIC_PORTAL_URL! + "/videos/promotion?name=breakout"
}

export const handleToMoblieLogin = () => {
  window.location.href = process.env.NEXT_PUBLIC_URL + "/mobile/login"
}
export const handleToMoblieList = (url: string, suburl: string) => {
  window.location.href =
    process.env.NEXT_PUBLIC_URL + "/mobile/list" + `?name=${url}&subname=${suburl}`
}

export const handleToMobileCollection = () => {
  window.location.href = process.env.NEXT_PUBLIC_URL! + "/mobile/Collection?name=Goods"
}

export const handleToMobileUser = () => {
  window.location.href = process.env.NEXT_PUBLIC_URL! + "/mobile/user"
}

export const handleToUrl = (url: string) => {
  window.location.href = process.env.NEXT_PUBLIC_URL + url
}
