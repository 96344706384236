/*
 * @Descripttion: 
 * @version: 
 * @Author: ared
 * @Date: 2022-10-27 14:46:04
 * @LastEditors: ared
 * @LastEditTime: 2022-10-28 14:37:09
 */
import styles from "../index.module.scss";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import newGif from "@images/new.gif";
import adLogo from "@images/adwinsLogo.png";
const MenuList = (props: any) => {
    const { t } = useTranslation("nav");
    return (
        <ul className={styles.menus}>
            {props.menu.map((el: any) => {
                return (
                    <li
                        key={el.name}
                        className={`h-full flex items-center ${styles.menusItem} `}
                    >
                        <a href={el.href} className="relative">
                            {el.isNew ? (
                                <div className="absolute -right-[28px] -top-[14px]">
                                    <Image
                                        lazyBoundary="10px"
                                        src={newGif}
                                        width={36}
                                        height={18}
                                    ></Image>
                                </div>
                            ) : null}
                            <span>{t(el.name)}</span>
                            {el.children && el.children.length && (
                                <i className={`iconfont icon-a-10xiala1 ml-[4px] ${styles.iconArrow}`}></i>
                            )}
                        </a>
                        {el.children && el.children.length ? (
                            <div className={styles.menusDropdown}>
                                <ul className={styles.dropdownList}>
                                    {el.children.map((item: any) => {
                                        return (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={
                                                    styles.dropdownListItem
                                                }
                                            >
                                                {t(item.name)}
                                            </a>
                                        );
                                    })}
                                </ul>
                            </div>
                        ) : null}
                    </li>
                );
            })}
            {/* <li className={`h-full flex items-center ${styles.menusItem} `}>
                <a
                    href={
                        "https://www.adwins.net/?channel_id=633&utm_source=Tikmeta&utm_campaign=&utm_content=&utm_medium=Tikmeta&utm_term="
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="relative"
                >
                    <Image
                        lazyBoundary="10px"
                        alt="Tikadpro is a Tiktok Ads automation tool. "
                        src={adLogo}
                        width={16}
                        height={16}
                    ></Image>
                    <span className="ml-[8px]">{t("advertising")}</span>
                </a>
            </li> */}
        </ul>
    );
};

export default MenuList;
