import Cookies from "js-cookie";

const TokenKey = "authorized-token";

type paramsMapType = {
    name: string;
    // domain: string;
    expires: number;
    accessToken: string;
    email: string;
    userId: string;
    locale: string;
};

// 获取token
export function getToken() {
    // 此处与TokenKey相同，此写法解决初始化时Cookies中不存在TokenKey报错
    return Cookies.get("authorized-token");
}

/**
 * 是否过期
 * @returns  true token过期
 */
export const isExprise = () => {
    const token = getToken();
    if (!token) return true;
    const data = JSON.parse(token);
    const now = new Date().getTime();
    return parseInt(data?.expires || 0) - now <= 0;
};

export function getStorageInfo() {
    const info = getToken();
    return !info ? info : JSON.parse(info);
}
// 设置token以及过期时间（cookies、sessionStorage各一份）
// 后端需要将用户信息和token以及过期时间都返回给前端，过期时间主要用于刷新token
export function setToken(data: {
    token: any;
    expires: any;
    nickName: any;
    domain: any;
    email: any;
    userId: any;
}) {
    const { token, expires, nickName, domain, email, userId } = data;
    const paramsMap = {
        name: nickName,
        expires: Date.now() + parseInt(expires || 7 * 24 * 60 * 60 * 1000),
        accessToken: token,
        email,
        userId,
    };
    const dataString = JSON.stringify(paramsMap);
    const temp = window.location.host.split(".").reverse();
    const root_domain = "." + temp[1] + "." + temp[0];
    paramsMap.expires > 0
        ? (document.cookie = `authorized-token=${dataString};expires = ${
              paramsMap.expires / 86400000
          };domain=${domain || root_domain};path=/`)
        : (document.cookie = `authorized-token=${dataString};path=/`);
}

// 删除token
export function removeToken() {
    deleteAllCookies();
    Cookies.remove(TokenKey);
    clearCookie();
    sessionStorage.removeItem(TokenKey);
}

function clearCookie() {
    const temp = window.location.host.split(".").reverse();
    const root_domain = "." + temp[1] + "." + temp[0];
    /*eslint no-useless-escape: "off"*/
    const keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
        for (let i = keys.length - 1; i > -1; i--) {
            document.cookie =
                keys[i] +
                "=0;expires=" +
                new Date(0).toUTCString() +
                ";path=/;domain=" +
                root_domain;
        }
    }
}

function deleteAllCookies() {
    const cookies = document.cookie.split(";");
    // console.log("ppppp", cookies);
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export const getDomain = () => {
    const temp = window.location.host.split(".").reverse();
    return "." + temp[1] + "." + temp[0];
};
