import { useTranslation } from "next-i18next";
import Switcher from "@@/local-switcher/index";
import styles from "./index.module.scss";
import Link from "next/link";
import { useRouter } from "next/router";
import { LegacyRef, useEffect, useRef, useState } from "react";
import Logo from "@/public/images/logo@2x.png";
import Image from "next/image";
import UserPanel from "@@/user-panel/index";
import { useWidth } from "@/utils/resize";
import googleOneTap from "@/utils/googleOneTab.js";
import { googleOneLogin, googleClientId } from "@/service";
import Channel from "@/utils/channel";
import { setToken } from "@/utils/auth";
import adLogo from "@images/adwinsLogo.png";
import ChromeLogo from "@images/chrome@2x.png";
import newGif from "@images/new.gif";
import Node from "@/utils/selector";
import { getStorageInfo } from "@/utils/auth";
import MenuList from "./components/MenuList";
import MobileMenuList from "./components/MobileMenuList";
// import orange from "@/utils/lucky-orange";
import dynamic from "next/dynamic";
import { trackEventFn } from "@/utils/gtag";
// const UserPanel = dynamic(import("@@/user-panel/index"));
// const Switcher = dynamic(import("@@/local-switcher/index"));

const Nav = () => {
    const [show, setShow] = useState(false);
    const { t, i18n } = useTranslation("nav");
    const router = useRouter();
    const { width, display } = useWidth();
    const [isOpen, setIsOpen] = useState(false);
    // console.log(width);
    const menu = [
        {
            name: "findGoods",
            icon: "icon-shangpin-xian",
            isNew: true,
            show: false,
            children: [
                {
                    name: "hotGoods",
                    href: "/portal/products/sales",
                    mhref: "/mobile/products/sales ",
                },
                {
                    name: "searchGoods",
                    href: "/portal/discovery/products?keyword=",
                },
            ],
        },
        {
            name: "findInfluencer",
            icon: "icon-gerenzhongxin1",
            show: false,
            children: [
                {
                    name: "salesChampion",
                    href: "/portal/influencers/sales",
                    mhref: "/mobile/influencers/sales",
                },
                {
                    name: "searchInfluencers",
                    href: "/portal/discovery/influencers?keyword=",
                    mhref: "/mobile/discovery/influencers?keyword=",
                },
            ],
        },
        {
            name: "findVideo",
            icon: "icon-shipinzhibo",
            show: false,
            children: [
                {
                    name: "GoodsVideos",
                    href: "/portal/videos/products",
                    mhref: "/mobile/videos/products",
                },
                {
                    name: "ProductVideos",
                    href: "/portal/videos/promotion?name=breakout",
                    mhref: "/mobile/videos/promotion",
                },
                {
                    name: "searchVideos",
                    href: "/portal/discovery/videos?keyword=",
                    mhref: "/mobile/discovery/videos?keyword=",
                },
            ],
        },
        {
            name: "ads",
            icon: "icon-shangpin-xian",
            show: true,
            isNew: true,
            mVisible: true, // 移动端暂时隐藏
            children: [
                {
                    name: "soaringList",
                    href: "/portal/ads/soaringList",
                },
                {
                    name: "adsLibrary",
                    href: "/portal/discovery/ads?keyword=",
                },
            ],
        },
        {
            name: "findContent",
            icon: "icon-sucai2",
            show: false,
            children: [
                {
                    name: "hotMusics",
                    href: "/portal/content/songs?name=breakout",
                    mhref: "/mobile/content/songs?name=breakout",
                },
                {
                    name: "searchSongs",
                    href: "/portal/discovery/songs?keyword=",
                    mhref: "/mobile/discovery/songs?keyword=",
                },
                {
                    name: "hotTags",
                    href: "/portal/content/hashtags?name=breakout",
                    mhref: "/mobile/content/hashtags?name=breakout",
                },
                {
                    name: "searchHashtags",
                    href: "/portal/discovery/hashtags?keyword=",
                    mhref: "/mobile/discovery/hashtags?keyword=",
                },
            ],
        },
        {
            name: "blogs",
            icon: "icon-boke",
            href: `${router.locale === "zh" ? "/zh" : ""}/blogs`,
            mhref: `${router.locale === "zh" ? "/zh" : ""}/blogs`,
        },
    ];

    const { locales, locale: activeLocale } = router;
    const handleMobileClose = () => {
        if (typeof window !== "undefined") {
            const nav = new Node(".mobile-nav").el;
            const modal = new Node(".mobileModal").el;
            const mask = new Node(".modal-mask");
            nav?.classList.remove("mobile-nav-in");
            nav?.classList.add("mobile-nav-out");
            mask.remove();

            if (modal) modal.style.display = "none";
            setTimeout(() => {
                new Node(".nav").el?.classList.remove("mobile");
                document.body.style.overflow = "auto";
                setIsOpen(false);
            }, 400);
        }
    };
    const handleMobileOpen = () => {
        if (typeof window !== "undefined") {
            setIsOpen(true);
            setTimeout(() => {
                const nav = new Node(".mobile-nav").el;
                const modal = new Node(".mobileModal").el;
                if (nav) nav.style.display = "block";
                nav?.classList.remove("mobile-nav-out");
                nav?.classList.add("mobile-nav-in");
                if (modal) modal.style.display = "block";
                new Node(".nav").el?.classList.add("mobile");
                const div = document.createElement("div");
                div.className = "modal-mask";
                document.body.append(div);
                document.body.style.overflow = "hidden";
                const mask = new Node(".modal-mask").el;
                mask?.addEventListener("click", handleMobileClose);
                if (mask) mask.style.display = "block";
            });
        }
    };
    const handleLangList = () => {
        setShow(!show);
    };
    const toSearch = () => {
        window.location.href = process.env.NEXT_PUBLIC_MOBILE_URL! + "/search";
    };
    const handleLogin = () => {
        window.location.href =
            width <= 900
                ? process.env.NEXT_PUBLIC_LOGIN_MOBILE_URL!
                : process.env.NEXT_PUBLIC_LOGIN_URL! +
                  "?fromPath=" +
                  window.location.href;
    };

    const handleToPortal = () => {
        window.location.href = process.env.NEXT_PUBLIC_PORTAL_URL!;
    };
    const getPortalLink = () => {
        return process.env.NEXT_PUBLIC_PORTAL_URL!;
    };

    const googleOneLoginFn = async () => {
        // @ts-ignore
        // console.log("=======", process.env.NEXT_PUBLIC_MODE);
        const data = await googleClientId();
        // console.log(data);

        const options = {
            client_id: (data?.data && data?.data?.clientId) || "wdzFsdx",
            autoSelect: false, // optional
            cancelOnTapOutside: true, // optional
            context: "signin", // optional
        };
        console.log(data);

        // @ts-ignore
        googleOneTap(options, async (response) => {
            // console.log(response);

            const params = {
                client_id: response.clientId,
                id_token: response.credential,
                source: "google",
                system_id: 8,
                channel_id: Channel.getKey(),
            };

            // @ts-ignore
            const res = await googleOneLogin(params);
            // @ts-ignore
            if (!res.code) {
                //去除channel埋点
                Channel.remove();
                setToken(res.data || Object.create(null));
                trackEventFn
                    .signUp(
                        { method: "GoogleOneTap" },
                        res.data.isRegister || res.data.is_register
                    )
                    .finally(() => {
                        window.history.go(0);
                    });
            }
        }).then((res) => {
            // @ts-ignore
            window.google.accounts.id.prompt();
        });
    };

    const urlMap = {
        home: process.env.NEXT_PUBLIC_URL,
        influencer: process.env.NEXT_PUBLIC_PORTAL_URL! + "/influencer/rising",
        tag:
            process.env.NEXT_PUBLIC_PORTAL_URL! +
            "/material/tabs?name=tabsSoaring",
        video:
            process.env.NEXT_PUBLIC_PORTAL_URL! + "/videos/list?name=soaring",
        music:
            process.env.NEXT_PUBLIC_PORTAL_URL! +
            "/material/music?name=musicSoaring",
        // ads: process.env.NEXT_PUBLIC_ADS,
        ads: "https://www.adwins.net/?channel_id=633&utm_source=Tikmeta&utm_campaign=&utm_content=&utm_medium=Tikmeta&utm_term= ",
        monitor: process.env.NEXT_PUBLIC_PORTAL_URL! + "/toolkit/monitor",
        productVideo: process.env.NEXT_PUBLIC_PORTAL_URL! + "/goods/goodsList",
        products:
            process.env.NEXT_PUBLIC_MOBILE_URL! +
            "/list?name=E-commerce&subname=Products",
        productsVideo:
            process.env.NEXT_PUBLIC_MOBILE_URL! +
            "/list?name=E-commerce&subname=ProductVideos",
        mobileSearch:
            process.env.NEXT_PUBLIC_MOBILE_URL! + "/search?name=Influencers",
    };
    const handleToLink = (key: keyof typeof urlMap) => {
        window.location.href = urlMap[key] || "";
    };
    const getLink = (key: keyof typeof urlMap) => {
        return urlMap[key] || "";
    };

    function getCookie(cname: string) {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    }

    const [isAuthorized, setAuthorized] = useState(false);
    const [userName, setUserName] = useState();

    useEffect(() => {
        const isAuth = getCookie("authorized-token") ? true : false;
        if (isAuth) {
            setAuthorized(isAuth);
            setUserName(getStorageInfo().email);
        } else {
            googleOneLoginFn();
        }
    }, []);

    const navRef = useRef<HTMLDivElement>(null);

    const onScroll = (e: Event) => {
        if (typeof window !== "undefined") {
            const window = e.currentTarget as Window;
            if (!window.scrollY) {
                navRef.current?.classList.remove("nav-scroll");
            } else {
                navRef.current?.classList.add("nav-scroll");
            }
        }
    };

    useEffect(() => {
        if (router.pathname.indexOf("[slug]") === -1) {
            window.addEventListener("scroll", onScroll);
            return () => {
                window.removeEventListener("scroll", onScroll);
            };
        } else {
            navRef.current?.classList.add("nav-scroll");
        }
    }, []);

    return (
        <>
            <div
                className={`${styles.nav} nav nav-fixed-screen-width:min-w-[993Px]`}
                ref={navRef}
            >
                <div className="defeaultContainer mx-[auto] h-[70Px] flex justify-between">
                    <div className={styles.left}>
                        {width < 1024 ? (
                            <li className="mr-[12px] minlg1024:hidden">
                                <span
                                    className={styles.mobileOpen}
                                    onClick={handleMobileOpen}
                                >
                                    <i className="iconfont icon-a-47caidan !text-[24px]"></i>
                                </span>
                            </li>
                        ) : null}
                        <a
                            href={
                                getLink("home") +
                                (router.locale === "zh" ? "/zh" : "")
                            }
                            className={
                                styles.logo +
                                " md:!w-[100Px] md:!flex md:!items-center"
                            }
                        >
                            <Image
                                lazyBoundary="10px"
                                alt="TikMeta | TikTok Analytics &amp; Statistics &amp; Track,
                    Influencer Marketing and Product Selection Platform"
                                src={Logo}
                            ></Image>
                        </a>
                        {width > 1023 ? (
                            <MenuList menu={menu}></MenuList>
                        ) : null}
                    </div>
                    <div className={styles.right}>
                        <li
                            className={`${styles.login}  ${
                                isAuthorized ? "hidden" : ""
                            } minlg1024:!border-0`}
                        >
                            <a
                                onClick={handleLogin}
                                target={"_self"}
                                rel="noreferrer"
                                className="whitespace-nowrap  md:!font-[600] md:!pd-[10Px]"
                            >
                                {t("login")}
                            </a>
                        </li>
                        <li
                            className={`flex items-center  ${
                                width > 1024 ? "hidden" : ""
                            }`}
                        >
                            <a href={getLink("mobileSearch")}>
                                <i className="iconfont icon-a-51sousuo !text-[24px]"></i>
                            </a>
                        </li>
                        {width > 1024 ? (
                            <li className="md:!hidden">
                                <a
                                    className={styles.start}
                                    href={getPortalLink()}
                                    rel="noreferrer"
                                >
                                    {t("start")}
                                </a>
                            </li>
                        ) : null}

                        <UserPanel></UserPanel>
                        {/* {width > 1023 ? <Switcher></Switcher> : null} */}
                    </div>
                </div>
            </div>
            {width < 1024 && isOpen ? (
                <>
                    <div className={`${styles.mobileNav} mobile-nav`}>
                        <div
                            className={
                                styles.mobileTop +
                                " flex items-center justify-between"
                            }
                        >
                            <div>
                                <a
                                    href="https://www.tikmeta.com/"
                                    className={
                                        styles.logo +
                                        " md:!w-[100Px] md:!flex md:!items-center"
                                    }
                                >
                                    <Image
                                        lazyBoundary="10px"
                                        alt="TikMeta | TikTok Analytics &amp; Statistics &amp; Track,
                    Influencer Marketing and Product Selection Platform"
                                        src={Logo}
                                    ></Image>
                                </a>
                            </div>
                            <div className="flex item-center">
                                <span
                                    className={styles.mobileClose}
                                    onClick={handleMobileClose}
                                >
                                    <svg
                                        className="icon"
                                        viewBox="0 0 1024 1024"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        p-id="2243"
                                        width="200"
                                        height="200"
                                    >
                                        <path
                                            d="M283.456 223.338667l2.709333 2.496L512 451.626667l225.834667-225.813334a42.666667 42.666667 0 0 1 62.826666 57.621334l-2.496 2.709333L572.373333 512l225.813334 225.834667a42.666667 42.666667 0 0 1-57.621334 62.826666l-2.709333-2.496L512 572.373333 286.165333 798.165333a42.666667 42.666667 0 0 1-62.826666-57.621333l2.496-2.709333L451.626667 512 225.834667 286.165333a42.666667 42.666667 0 0 1 57.621333-62.826666z"
                                            p-id="2244"
                                            fill="#2E384E"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <MobileMenuList
                            menu={menu.filter((m) => !m?.mVisible)}
                            handleMobileClose={handleMobileClose}
                        ></MobileMenuList>
                    </div>
                    <div
                        className="fixed w-[15vw] top-0 right-0 h-[100vh] mobileModal"
                        onClick={handleMobileClose}
                    ></div>
                </>
            ) : null}
        </>
    );
};

export default Nav;
