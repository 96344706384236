import Fetch from "../utils/request";
import { getDayDate } from "@/utils/format";
// import database from "../global";
import { GetServerSidePropsContext } from "next";
import { ParsedUrlQuery } from "querystring";
const fetch = new Fetch();
const BaseUrl = process.env.NEXT_PUBLIC_API_PORTAL_URL;
const BaseUrl1 = process.env.NEXT_PUBLIC_USER_API_DOMAIN;
type ContextType = GetServerSidePropsContext<ParsedUrlQuery> | {};
export async function subscribe(options: any, context: ContextType) {
    // console.log("----", document.referrer);
    return await fetch.put(
        `${BaseUrl}/api/v1/website/subscribe`,
        context,
        options
    );
}

// export async function authorList(options: any) {
//     return await fetch.get(`${BaseUrl}/api/v1/seo/author/list`, options);
// }

export async function googleOneLogin(data: any, context: ContextType) {
    // @ts-ignore
    return await fetch.post(
        `${BaseUrl1}/api/v1/user/third-login/id-token/google`,
        context,
        data
    );
}
export async function googleClientId(context: ContextType) {
    // @ts-ignore
    // console.log("ppppp111", database.value);
    return await fetch.get(
        `${BaseUrl1}/api/v1/user/third-login/client-id/google?system_id=8`,
        context
    );
}

/**
 * 新增
 */

export async function getBlogDetail({
    seoUrl,
    language,
    context,
}: {
    seoUrl: any;
    language: any;
    context: ContextType;
}) {
    return await fetch.get(
        `${BaseUrl}/api/v1/blogs/${seoUrl}?language=${language}`,
        context
    );
}

export async function getBlogs({
    language,
    pageSize,
    pageNum,
    context,
}: {
    language: any;
    pageSize: any;
    pageNum: any;
    context: ContextType;
}) {
    return await fetch.get(
        `${BaseUrl}/api/v1/blogs?language=${language}&page_size=${pageSize}&page_num=${pageNum}`,
        context
    );
}
export async function getCountt(headers: any, id: any) {
    return await fetch.get2(`https://tiktok.livecounts.io/user/${id}`, headers);
}
export async function getTopFive(context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/homepage/hot-influencers`,
        context
    );
}
export async function getTopVideo(context: ContextType) {
    // console.log(";;;;;;", this);
    return await fetch.get(`${BaseUrl}/api/v1/homepage/hot-videos`, context);
}
export async function getTopContent(context: ContextType) {
    // console.log(";;;;;", this);
    return await fetch.get(`${BaseUrl}/api/v1/homepage/hot-content`, context);
}

export async function getUserCount(context: ContextType) {
    return await fetch.get(`${BaseUrl}/api/v1/website/author`, context);
}

/**
 *
 * @returns 获取seo 音乐目录
 */
export async function getSeoMusics(
    // cursor: number,
    // country_code: any,
    // title_pre: any
    paramStr: any,
    context: ContextType
) {
    let x = `${BaseUrl}/api/v1/seo/music/catalogue${paramStr}`;
    // console.log(x);

    return await fetch.get(x, context);
}

/**
 *
 * @returns 获取seo 音乐详情
 */
export async function getSeoMusicDetails(id: string, context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/seo/tag/otherSimilar/${id}`,
        context
    );
}
//手机端
export async function getVersion(context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/versions?rank_type=AUTHOR_FOLLOWER_RISING&rank_cycle=H24`,
        context
    );
}
export async function getUsers(version: any, context: ContextType) {
    return await fetch.post(
        `${BaseUrl}/api/v1/rank/author_follower_rising_rank`,
        context,
        {
            cycle: "H24",
            version: version,
            cursor: 0,
        }
    );
}
export async function getUsers2(version: any, context: ContextType) {
    return await fetch.post(
        `${BaseUrl}/api/v1/rank/author_potential_rank`,
        context,
        {
            cycle: "H24",
            version: version,
            cursor: 0,
        }
    );
}
export async function getUserTopSale(version: any, context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/sales_author_rank?cycle=H24&is_commerce=false&version=${version}&cursor=0&sort=19&country_code=GB&size=3`,
        context
    );
}
export async function getVideo(version: any, context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/video_like_rising_rank?cycle=H24&version=${version}&cursor=0`,
        context
    );
}
export async function getVideo2(version: any, context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/video_play_total_rank?cycle=H24&version=${version}&cursor=0`,
        context
    );
}
export async function getMusics(version: any, context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/music_rising_rank?cycle=W1&version=${version}&cursor=0`,
        context
    );
}
export async function getTags(version: any, context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/tag_rising_rank?cycle=W1&version=${version}&cursor=0`,
        context
    );
}
export async function getTagVersion(context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/versions?rank_type=TAG_RISING&rank_cycle=W1`,
        context
    );
}

export async function getMusicVersion(context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/versions?rank_type=MUSIC_RISING&rank_cycle=W1`,
        context
    );
}

//商品
export async function getGoodsVideoList(context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/homepage/hot-promote-videos`,
        context
    );
}

export async function getGoodsList(version: any, context: ContextType) {
    return await fetch.get(`${BaseUrl}/api/v1/homepage/goods-rank`, context);
}

export async function getGoodsVersion(context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/versions?rank_type=PRODUCT_RISING&rank_cycle=H24`,
        context
    );
}

export async function getProductVersion(context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/versions?rank_type=VIDEO_COMMERCE_RISING&rank_cycle=H24`,
        context
    );
}

export async function getProductList(version: any, context: ContextType) {
    return await fetch.get(
        `${BaseUrl}/api/v1/rank/video_commerce_rising_rank?cycle=H24&version=${version}&cursor=0`,
        context
    );
}

export async function getGoodsTrend(context: ContextType) {
    const dateObj = getDayDate(7);
    return await fetch.get(`${BaseUrl}/api/v1/homepage/product/trend`, context);
}

export async function getRegions(context: ContextType) {
    return await fetch.get(`${BaseUrl}/api/v1/tikmeta/portal/region`, context);
}
