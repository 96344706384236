import Navbar from "./navbar/index";
import Footer from "./footer/index";
import FooterBtns from "./footerBtns/index";
import Script from "next/script";

export default function Layout({ children }: { children: any }) {
  const customNavs = ["User"];
  const customFooters = ["User"];
  const custombtns = ["User"];
  // console.log("children", children?.type?.name);
  return (
    <>
      {/* iconfont 链接 */}
      <Script
        id="alicdn"
        src="//at.alicdn.com/t/font_3347340_qro4dyqbgpi.js"
      ></Script>
      {!customNavs.includes(children?.type?.name) ? <Navbar /> : null}
      <main>{children}</main>
      {!customFooters.includes(children?.type?.name) ? <Footer /> : null}
      {!custombtns.includes(children?.type?.name) ? <FooterBtns /> : null}
    </>
  );
}
