// @flow
import * as React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import styles from "./index.module.scss";
import Logo from "@/public/images/logo-white.png";
import Image from "next/image";
import { useRouter } from "next/router";
import { useWidth } from "@/utils/resize";
import { subscribe } from "@/service/index";
import adLogo from "@images/adwinsLogo.png";
import ChromeLogo from "@images/chrome@2x.png";
import {
    handleToPortal,
    handleToTag,
    handleToVideo,
    handleToMusic,
    handleToPotential,
} from "@/utils/sys";
import facebook from "@/public/images/footer/facebook@2x.png";
import instagram from "@/public/images/footer/instagram@2x.png";
import tw from "@/public/images/footer/tw@2x.png";
import youtube from "@/public/images/footer/youtube@2x.png";

// const Modal = dynamic(() => import('antd/lib/modal'))
// const BackTop= dynamic(() => import('antd/lib/back-top'))
import { Modal, BackTop } from "antd";
type Props = {};
const Index = (props: Props) => {
    const { t, i18n } = useTranslation(["footer"]);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const router = useRouter();
    const [isAuth, setIsAuth] = useState(false);
    const { width, display } = useWidth();
    const isPc = width > 1023;
    const { locales, locale } = router;
    const isHideTitle = React.useMemo(() => {
        //  不要展示页脚标题的页面
        const footerTitleNotShownPages = ["/insight", "/report/[slug]"];
        return footerTitleNotShownPages.includes(router.pathname);
    }, [router]);

    const errorMessage = (i18nString: string) => {
        Modal.error({
            content: t(i18nString),
        });
    };
    const successMessage = (i18nString: string) => {
        Modal.success({
            content: t(i18nString),
        });
    };

    const handleClick = (e: any) => {
        var reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
        if (!email) return errorMessage("Enter email");
        if (!message) return errorMessage("Enter message");
        if (!reg.test(email)) return errorMessage("Error email");

        subscribe({ contact: email, message: message }, {}).then((res) => {
            !res.code && successMessage("Sent successfully");
            !res.code && setEmail("");
            !res.code && setMessage("");
        });
    };
    function getCookie(cname: string) {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    }
    const handleInput = (e: any) => {
        // console.log("ppp", e);
        setEmail(e.target.value);
    };
    const handleTextAreaInput = (e: any) => {
        setMessage(e.target.value);
    };
    const handleToPortal1 = () => {
        if (width > 900) {
            handleToPortal();
        } else {
            window.location.href =
                process.env.NEXT_PUBLIC_URL + "/mobile/login";
        }
    };
    const jump = (str: string) => {
        window.location.href = `${locale === "en" ? "/" : "/zh/"}` + str;
    };
    useEffect(() => {
        const isAuth = getCookie("authorized-token") ? true : false;
        setIsAuth(isAuth);
    }, []);
    return (
        <div className={`${styles.footer}`}>
            <div
                className={`${isHideTitle || isAuth ? "!hidden" : ""} ${
                    styles.footerTitle
                } h-[470Px] lg:!px-[20px] sm600:!h-[auto] rounded-[24Px] md:mt-[25Px]   md:mb-[50Px] md:mx-[auto] md:!w-[calc(100%-40Px)] md:!Px-[50Px]`}
            >
                <div
                    className={
                        styles.name +
                        " popins-title  w-[787px] text-[48px] !h-[auto] lg:!w-[100%]   md:!leading-[tights] md:!text-[calc(100vw*(16/375))]"
                    }
                >
                    {t("footer-title")}
                </div>
                <div
                    className={`${styles.button} md:!mt-[36Px] md:!w-[218px] md:!h-[48px] md:!leading-[48px] md:!text-[16px]`}
                    onClick={handleToPortal1}
                >
                    {t("footer-btn-text")}
                </div>
            </div>
            <div className={styles.footerBottom + " md:!pt-[50Px]"}>
                <div
                    className={
                        styles.footerBottomContent +
                        " flex lg:block justify-between defeaultContainer"
                    }
                >
                    <div
                        className={
                            styles.info +
                            " lg:float-left lg:!w-[40%] sm768:!w-[100%] sm768:mb-[90Px] md:!float-none md:!mb-[0Px] md:pb-[50Px] "
                        }
                    >
                        <div className={styles.logo}>
                            <Image
                                lazyBoundary="10px"
                                alt=""
                                src={Logo}
                            ></Image>
                        </div>
                        <div
                            className={`Poppins mt-[32Px] font-medium text-[#fff] ${
                                isPc ? "text-[20px]" : "text-[14px]"
                            }`}
                        >
                            marketing@tikmeta.com
                        </div>
                        <div className="Poppins mt-[32Px] text-[5px] font-medium text-[#fff]  flex">
                            <a
                                rel="noreferrer"
                                href="https://www.facebook.com/Tikmeta-107508038615030/"
                                target={"_blank"}
                                style={{
                                    background: "rgba(255, 255, 255, 0.1)",
                                }}
                                className="cursor-pointer mx-[9Px] w-[40Px] block h-[40Px] p-[8Px] rounded-[100%] relative"
                            >
                                <Image
                                    lazyBoundary="10px"
                                    layout="intrinsic"
                                    src={facebook}
                                    alt=""
                                    width="40"
                                    height="40"
                                ></Image>
                            </a>
                            <a
                                rel="noreferrer"
                                href="https://www.instagram.com/tikmeta/"
                                target={"_blank"}
                                style={{
                                    background: "rgba(255, 255, 255, 0.1)",
                                }}
                                className="cursor-pointer mx-[9Px] w-[40Px] block h-[40Px]  p-[8Px]  rounded-[100%] relative"
                            >
                                <Image
                                    lazyBoundary="10px"
                                    layout="intrinsic"
                                    src={instagram}
                                    alt=""
                                    width="40"
                                    height="40"
                                ></Image>
                            </a>
                            <a
                                rel="noreferrer"
                                href="https://twitter.com/tikmeta"
                                target={"_blank"}
                                style={{
                                    background: "rgba(255, 255, 255, 0.1)",
                                }}
                                className="cursor-pointer mx-[9Px] w-[40Px] block h-[40Px]  p-[8Px] rounded-[100%]  relative"
                            >
                                <Image
                                    lazyBoundary="10px"
                                    layout="intrinsic"
                                    src={tw}
                                    alt=""
                                    width="40"
                                    height="40"
                                ></Image>
                            </a>
                            <a
                                rel="noreferrer"
                                href="https://www.youtube.com/channel/UCyP8s-7nzhZe1Q9w7VKknpg"
                                target={"_blank"}
                                style={{
                                    background: "rgba(255, 255, 255, 0.1)",
                                }}
                                className="cursor-pointer mx-[9Px] w-[40Px] block h-[40Px]  p-[8Px]  rounded-[100%]  relative"
                            >
                                <Image
                                    lazyBoundary="10px"
                                    layout="intrinsic"
                                    src={youtube}
                                    alt=""
                                    width="40"
                                    height="40"
                                ></Image>
                            </a>
                        </div>
                        {width > 768 ? (
                            <div
                                className="Poppins mt-[32Px] text-[14px] whitespace-nowrap"
                                style={{
                                    color: "rgba(255, 255, 255, 0.5)",
                                }}
                            >
                                © Tikmeta. All Rights Reserved 2022
                            </div>
                        ) : null}
                    </div>
                    <div className={styles.hrefDiv}>
                        <div className={styles.href}>
                            <div className="Poppins head text-[20px] font-semibold text-[#fff]">
                                {t("productsServices")}
                            </div>
                            <div
                                className="Poppins mt-[25Px] text-[15px]  cursor-pointer "
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="nofollow"
                                    href={
                                        isPc
                                            ? "/portal/products/sales"
                                            : "/mobile/products/sales"
                                    }
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    {router.locale === "en"
                                        ? "Top Sales Products"
                                        : "热销商品"}
                                </a>
                            </div>
                            <div
                                className="Poppins mt-[25Px] text-[15px] cursor-pointer "
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="nofollow"
                                    href={
                                        isPc
                                            ? "/portal/influencers/sales"
                                            : "/mobile/influencers/sales"
                                    }
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    {router.locale === "en"
                                        ? "Top Sales Influencers"
                                        : "带货达人"}
                                </a>
                            </div>

                            <div
                                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                                onClick={handleToPortal}
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="nofollow"
                                    href={
                                        isPc
                                            ? "/portal/videos/products"
                                            : "/mobile/videos/products"
                                    }
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    {router.locale === "en"
                                        ? "Product Videos"
                                        : "带货视频"}
                                </a>
                            </div>
                            <div
                                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                                onClick={handleToPortal}
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="nofollow"
                                    href={
                                        isPc
                                            ? "/portal/videos/promotion?name=breakout"
                                            : "/mobile/videos/promotion?name=breakout"
                                    }
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    {router.locale === "en"
                                        ? "Promotion Videos"
                                        : "种草视频"}
                                </a>
                            </div>

                            {isPc ? (
                                <div
                                    className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    <a
                                        rel="nofollow"
                                        href={
                                            isPc
                                                ? "/portal/ads/soaringList"
                                                : "/portal/ads/soaringList"
                                        }
                                        style={{
                                            color: "rgba(255, 255, 255, 0.8)",
                                        }}
                                    >
                                        {router.locale === "en"
                                            ? "Breakout Ads"
                                            : "飙升广告"}
                                    </a>
                                </div>
                            ) : null}
                            {/* <div
                                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                                onClick={handleToPortal}
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="nofollow"
                                    href={
                                        isPc
                                            ? "/portal/material/music"
                                            : "/mobile/list?name=Contents&subname=Musics"
                                    }
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    {router.locale === "en"
                                        ? "Song"
                                        : "热门音乐"}
                                </a>
                            </div>
                            <div
                                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                                onClick={handleToPortal}
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="nofollow"
                                    href={
                                        isPc
                                            ? "/portal/material/tabs"
                                            : "/mobile/list?name=Contents&subname=Hashtags"
                                    }
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    {router.locale === "en"
                                        ? "Hashtag"
                                        : "热门标签"}
                                </a>
                            </div> */}
                        </div>
                        <div className={styles.href}>
                            <div className="whitespace-nowrap Poppins head text-[20px] font-semibold text-[#fff]">
                                {t("tikTokRankings")}
                            </div>

                            <div
                                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="noreferrer"
                                    href={`${
                                        locale == "en" ? "" : "/zh"
                                    }/influencers/1`}
                                    target={"_blank"}
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    {t("Influencers")}
                                </a>
                            </div>
                            <div
                                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="noreferrer"
                                    href={`${
                                        locale == "en" ? "" : "/zh"
                                    }/videos/1`}
                                    target={"_blank"}
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    {t("Videos")}
                                </a>
                            </div>
                            <div
                                className="Poppins mt-[25Px] text-[15px]  cursor-pointer "
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="noreferrer"
                                    href={`${
                                        locale == "en" ? "" : "/zh"
                                    }/hashtags/1`}
                                    target={"_blank"}
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    {t("Tags")}
                                </a>
                            </div>
                            <div
                                className="Poppins mt-[25Px] text-[15px]  cursor-pointer "
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="noreferrer"
                                    href={`${
                                        locale == "en" ? "" : "/zh"
                                    }/songs/1`}
                                    target={"_blank"}
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    {t("Music")}
                                </a>
                            </div>

                            {/* <div
                                className="Poppins mt-[25Px] text-[15px]  cursor-pointer "
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="noreferrer"
                                    href={`https://www.adwins.net/?channel_id=633&utm_source=Tikmeta&utm_campaign=&utm_content=&utm_medium=Tikmeta&utm_term= `}
                                    target={"_blank"}
                                    ref="noreferrer"
                                    className="flex items-center"
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    <Image
                                        src={adLogo}
                                        width={16}
                                        height={16}
                                    ></Image>
                                    <span className="ml-[6px]">
                                        {t("advertising")}
                                    </span>
                                </a>
                            </div> */}
                            <div
                                className="Poppins mt-[25Px] text-[15px]  cursor-pointer "
                                style={{
                                    color: "rgba(255, 255, 255, 0.8)",
                                }}
                            >
                                <a
                                    rel="noreferrer"
                                    href={`${
                                        locale == "en" ? "" : "/zh"
                                    }/insight`}
                                    target={"_blank"}
                                    className="flex items-center"
                                    style={{
                                        color: "rgba(255, 255, 255, 0.8)",
                                    }}
                                >
                                    <Image
                                        src={ChromeLogo}
                                        width={16}
                                        height={16}
                                    ></Image>
                                    <span className="ml-[6px]">
                                        {t("extension")}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    {width > 900 ? (
                        <div className="lg:w-[100%] lg:pt-[70Px] md:!hidden ">
                            <div className="Poppins head text-[20px] font-semibold text-[#fff]">
                                {t("callback")}
                            </div>
                            <input
                                onInput={handleInput}
                                placeholder={t("Your Email")}
                                style={{
                                    background: "rgba(255, 255, 255, 0.1)",
                                }}
                                className="pl-[20px] text-[12px] w-[335Px] h-[40Px] rounded-[4Px] mt-[25Px] py-[10Px] Px-[16Px] text-[#fff] lg:!w-[100%]"
                            ></input>
                            <textarea
                                onInput={handleTextAreaInput}
                                placeholder={t("Leave us a message")}
                                className="pl-[20px]  text-[12px] mt-[10Px] w-[335Px] rounded-[4Px]  h-[115Px] py-[10Px] Px-[16Px] text-[#fff]  lg:!w-[100%]"
                                style={{
                                    background: "rgba(255, 255, 255, 0.1)",
                                }}
                            ></textarea>
                            <button
                                onClick={handleClick}
                                className="text-[#fff]  mt-[25Px]  w-[126Px] py-[10Px] h-[40px] bg-[#FF3E5F] text-[14px] rounded-[20Px] backdrop-blur-[4.5Px]"
                            >
                                {t("Send")}
                            </button>

                            {width < 768 ? (
                                <div
                                    className={
                                        "Poppins pt-[40Px] text-[14px]  whitespace-nowrap " +
                                        ` ${styles.last}`
                                    }
                                    style={{
                                        color: "rgba(255, 255, 255, 0.5)",
                                    }}
                                >
                                    © Tikmeta. All Rights Reserved 2022
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
            {width < 1024 ? (
                <div className="minlg1024:hidden">
                    <BackTop>
                        <div className="bg-[#00000073] rounded-full w-[40px] h-[40px] flex flex-col justify-center items-center">
                            <svg
                                className="icon"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="4940"
                                width="20"
                                height="20"
                            >
                                <path
                                    d="M455.387429 333.238857l-242.834286 284.233143a36.571429 36.571429 0 0 0 27.830857 60.342857h147.126857v173.092572a124.635429 124.635429 0 1 0 249.307429 0v-173.129143h136.155428a36.571429 36.571429 0 0 0 28.233143-59.794286l-233.764571-283.794286a73.142857 73.142857 0 0 0-112.054857-0.950857zM249.782857 36.571429h512a91.428571 91.428571 0 0 1 0 182.857142h-512a91.428571 91.428571 0 1 1 0-182.857142z"
                                    p-id="4941"
                                    fill="#ffffff"
                                ></path>
                            </svg>
                        </div>
                    </BackTop>
                </div>
            ) : null}
        </div>
    );
};

const Footer = () => {
    const { width, display } = useWidth();
    const isPc = width > 1024;
    return !isPc ? (
        <div className={`${styles.mobileFooter}`}>
            <Index />
        </div>
    ) : (
        <div>
            <Index />
        </div>
    );
};

export default Footer;
