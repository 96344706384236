// import "antd/dist/antd.css";
// require("../styles/antd.less");
import "../styles/tailwind.css";
import "../styles/globals.css";
import "../styles/animate.css";
import "../styles/custom.css";
import "../styles/search.css";
// import "../styles/odometer-theme-default.css";
import Layout from "../components/layout";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import * as gtag from "../utils/gtag";
import Head from "next/head";
import Channel from "@/utils/channel";
import buriedsdk from "../utils/zz-buried/index";
import { getCookie, setCookie } from "cookies-next";
import { getDomain } from "@/utils/auth";

const isProd = process.env.NODE_ENV === "production";
// import buriedsdk from "../utils/zz-buried/index"
interface InfoType {
    locale: string;
}

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const { locales, locale: activeLocale } = router;
    const openPageView = false; //是否不关闭自动上报pv
    useEffect(() => {
        let token = getCookie("authorized-token") as string;
        let data = (token ? JSON.parse(token) : token) as any;
        const temp = window.location.host.split(".").reverse();
        const root_domain = "." + temp[1] + "." + temp[0];

        buriedsdk.init(
            8,
            process.env.NODE_ENV === "production" ? "prod" : "test",
            token ? data.accessToken : undefined,
            openPageView
        );
        buriedsdk.buried({
            event: "pageView",
            URL: process.env.NEXT_PUBLIC_USER_API_DOMAIN,
        });
        if (process.browser) {
            setCookie("CURRENT_URL", window?.location?.href);
            if (localStorage?.getItem("BURIEDSDK_DEVICEID")) {
                setCookie(
                    "BURIEDSDK_DEVICEID",
                    localStorage?.getItem("BURIEDSDK_DEVICEID")
                );
            }
            if (getCookie("BURIEDSDK_DEVICEID")) {
                localStorage?.setItem(
                    "BURIEDSDK_DEVICEID",
                    getCookie("BURIEDSDK_DEVICEID") as string
                );
            }
        }
        const handleLocale = () => {
            // let localeCookie = getCookie("responsive-locale") as any;

            // let data = (
            //     localeCookie ? JSON.parse(localeCookie) : localeCookie
            // ) as InfoType;
            // const domain =
            //     process.env.NEXT_PUBLIC_URL === "https://www.tikmeta.com"
            //         ? ".tikmeta.com"
            //         : ".tikmeta.net";
            // data.locale = router.locale as string;
            // setCookies("cookieLocale", router.locale, {
            //     path: "/",
            //     domain,
            // });
            // }

            localStorage.setItem(
                "responsive-locale",
                JSON.stringify({ locale: router.locale })
            );
        };
        handleLocale();
        const handleRouteChange = (url: URL, { shallow }: { shallow: any }) => {
            console.log("pppp4444", 1212);
            //保存channelid 埋点收集注册事件
            if (isProd) {
                gtag.pageview(url);
            }
            Channel.save(Channel.searchKey());
        };

        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        const handleClick = (e: any) => {
            setCookie("current-page", window.location.href);
            // console.log("~~~~~~~~~~", e);
        };
        window.addEventListener("click", handleClick);
        return () => {};
    }, []);

    return (
        <Layout>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
                ></meta>
            </Head>
           {/* @ts-ignore */}
            <Component {...pageProps} />
        </Layout>
    );
}

export default appWithTranslation(MyApp);
