// @flow
import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
// import { useWidth } from "@/utils/resize";
import styles from "./index.module.scss";
import { getCookie, removeCookies, setCookie } from "cookies-next";
import Image from "next/image";
import Link from "next/link";
import { useWidth } from "@/utils/resize";
import { removeToken } from "@/utils/auth";
interface InfoType {
    email: string;
}
type Props = {};

const Index = (props: Props) => {
    const [data, setData] = useState<InfoType>();
    const { width, display } = useWidth();

    const { t, i18n } = useTranslation(["nav"]);
    const handleToArchive = () => {
        window.location.href =
            process.env.NEXT_PUBLIC_PORTAL_URL + "/tool/favorites";
    };
    const handleToArchive1 = () => {
        window.location.href =
            process.env.NEXT_PUBLIC_PORTAL_URL + "/user/setting";
    };
    const handleLogout = () => {
        const domain =
            process.env.NEXT_PUBLIC_URL === "https://www.tikmeta.com"
                ? ".tikmeta.com"
                : ".tikmeta.net";
        // removeCookies("authorized-token", {
        //     path: "/",
        //     domain,
        // });
        // deleteAllCookies();
        removeToken();
        window.location.reload();
    };

    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    };
    const handleToSelp = () => {};
    const toUserCenter = () => {
        if (width < 1024) {
            window.location.href =
                process.env.NEXT_PUBLIC_MOBILE_URL! + "/User";
        }
    };
    useEffect(() => {
        let info = getCookie("authorized-token") as string;
        let data = (info ? JSON.parse(info) : info) as InfoType;
        setData(data);
    }, []);
    return (
        <>
            {data ? (
                <div
                    id="user-container"
                    className={
                        "relative mr-[20px] md:!mr-[0px]" + ` ${styles.userNav}`
                    }
                >
                    <span className={`${styles.userContainer} mt-[10px]`}>
                        <Image
                            lazyBoundary="10px"
                            onClick={toUserCenter}
                            alt=""
                            width={32}
                            height={32}
                            className={"nav-avtoar " + ``}
                            src="https://ttcdn.tikmeta.com/tikmeta/avtoar.png?imageMogr2/auto-orient/thumbnail/240x/strip/format/WEBP/quality/75!/ignore-error/1"
                        />
                        <ul
                            className={` ${styles.selects} md:!pb-[8px]  md:!-left-[124px] md:!w-[160px] lg1024:!hidden`}
                        >
                            <div className="flex items-center  md:!hidden">
                                <div className=" flex ml-[20px] w-[32px] h-[32px] items-center">
                                    <Image
                                        lazyBoundary="10px"
                                        className="flex-[0] w-[32px] h-[32px]"
                                        width={32}
                                        height={32}
                                        alt=""
                                        src="https://ttcdn.tikmeta.com/tikmeta/avtoar.png?imageMogr2/auto-orient/thumbnail/240x/strip/format/WEBP/quality/75!/ignore-error/1"
                                    />
                                </div>
                                <span className="break-all  flex-[1] ml-[20px] !font-bold ellipse-1">
                                    {data?.email}
                                </span>
                            </div>
                            {/* {width <= 900 ? (
                        <Link href="/user">
                            <li className="pl-[20px] !text-left">
                                <div className="flex items-center">
                                    <i className="inline-block iconfont icon-a-28dianzan1 !text-[16px] text-black"></i>
                                    <span className="ml-[20px] py-8px">
                                        {t("Center")}
                                    </span>
                                </div>
                            </li>
                        </Link>
                    ) : null} */}
                            <li
                                onClick={handleToArchive1}
                                className="pl-[20px] !text-left md:!hidden"
                            >
                                <div className="flex items-center">
                                    <i className="inline-block iconfont icon-gerenzhongxin1 !text-[16px] text-black"></i>
                                    <span className="ml-[20px] py-8px">
                                        {t("user")}
                                    </span>
                                </div>
                            </li>
                            <li
                                onClick={handleToArchive}
                                className="pl-[20px] !text-left md:!hidden"
                            >
                                <div className="flex items-center">
                                    <i className="inline-block iconfont icon-a-28dianzan1 !text-[16px] text-black"></i>
                                    <span className="ml-[20px] py-8px">
                                        {t("Archive")}
                                    </span>
                                </div>
                            </li>
                            <li
                                onClick={handleLogout}
                                className="pl-[20px] !text-left "
                            >
                                <div className="flex items-center">
                                    <i className="inline-block iconfont icon-tuichu1 !text-[16px] text-black"></i>
                                    <span className="ml-[20px] py-8px">
                                        {t("Logout")}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </span>
                </div>
            ) : null}
        </>
    );
};

export default Index;
